import { useContext, useEffect, useMemo, useState } from "react";

import { AuthContext } from "../../../../auth/authContext";
import { Organization } from "../../../../dtos/organization";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";

export function useOrganizationOptions(
    selectedOrganizationId: number | null,
    dealerId: number | null,
    canGetOptions: boolean,
) {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { orgApi } = useApi();
    const {
        isGlobalAdmin,
        isDealerAdmin,
        dealerId: assignedDealerId,
    } = useContext(AuthContext);

    useEffect(() => {
        let cancel = false;
        if (canGetOptions && (isGlobalAdmin || isDealerAdmin)) {
            const effectiveDealerId = dealerId || assignedDealerId;
            if (effectiveDealerId) {
                setIsLoading(true);
                orgApi.getForDealer(effectiveDealerId).then((r) => {
                    if (!cancel && r.result === AjaxResult.Success && r.data) {
                        setOrganizations(r.data);
                    }
                    setIsLoading(false);
                });
            } else if (isGlobalAdmin) {
                setIsLoading(true);
                orgApi.getAll().then((r) => {
                    if (!cancel && r.result === AjaxResult.Success && r.data) {
                        setOrganizations(r.data);
                    }
                    setIsLoading(false);
                });
            }
        }

        return () => {
            cancel = true;
        };
    }, [
        canGetOptions,
        orgApi,
        isGlobalAdmin,
        isDealerAdmin,
        assignedDealerId,
        dealerId,
    ]);

    const organizationOptions = useMemo(() => {
        return organizations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(it => ({ label: it.name, value: it.id }));
    }, [organizations]);

    const selectedOrganizationOption = useMemo(() => {
        return organizationOptions.find(it => it.value === selectedOrganizationId);
    }, [organizationOptions, selectedOrganizationId]);

    return { organizationOptions, selectedOrganizationOption, isLoading };
}
