import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { PageHeader } from '../../PageHeader';
import { SearchBar } from './searchBar';
import { DatePicker } from '@material-ui/pickers';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Pagination } from '../../shared/pagination';
import { TimeZoneSwitch } from '../../shared/TimeZoneSwitch';
import { makeStyles, styled } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import { Event } from '../../../dtos/event';
import AsyncSelect from 'react-select/async';
import { OrgSelect } from '../../shared/OrgSelect';
import { useApi } from '../../useApi';
import { ItemsPerPageInput } from '../../shared/itemsPerPageInput';
import { EventType } from '../../../enums/eventType';
import { UserLevel } from '../../../enums/userLevel';
import { HelpVideo } from '../../../enums/helpVideo';
import { ExportReportButton } from '../../shared/ExportReportButton';
import { ButtonsContainer } from '../../shared/ButtonsContainer';
import { ReportLogTable } from './ReportLogTable';

interface Props {
    roles?: any | null;
    dealerId?: number | null;
    orgId?: number | null;
    siteId?: number | null;
    to: Moment | null;
    from: Moment | null;
    itemsPerPage: number;
    eventType: EventType | null;
    eventTypeOptions: [number, string][];
    eventName: number | null;
    eventNameOptions: [number, string][];
    page: number;
    numberOfPages: number;
    isLoading: boolean;
    events: Event[];
    searchText: string;
    employeeToSearch: string | undefined;
    customerToSearch: string | undefined;
    requestOptions?: any | null;
    setEmployeeToSearch: (userName: string | undefined) => void;
    setCustomerToSearch: (userName: string | undefined) => void;
    setPage: (page: number) => void;
    selectEventName: (type: number | null) => void;
    selectEventType: (type: number | null) => void;
    setTo: (date: Moment | null) => void;
    setFrom: (date: Moment | null) => void;
    siteChangeCallback: (
        dealerId?: number | null,
        orgId?: number | null,
        siteId?: number | null
    ) => void;
    setItemsPerPage: (val: number) => void;
    search: (text: string) => void;
    filterByLocalTime: boolean | null;
    setFilterByLocalTime: (useLocalTime: boolean | null) => void;
}

export const ReportLogPage = ({
    roles,
    dealerId,
    orgId,
    siteId,
    events,
    setItemsPerPage,
    from,
    searchText,
    itemsPerPage,
    to,
    setFrom,
    setTo,
    eventType,
    selectEventType,
    eventTypeOptions,
    eventName,
    selectEventName,
    eventNameOptions,
    page,
    setPage,
    numberOfPages,
    isLoading,
    siteChangeCallback,
    search,
    setEmployeeToSearch,
    employeeToSearch,
    setCustomerToSearch,
    customerToSearch,
    requestOptions,
    filterByLocalTime,
    setFilterByLocalTime,
}: Props) => {
    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;
    const classes = useStyles();
    const { employeeApi, customerApi, eventApi } = useApi();

    const reportRequest = useMemo(() => {
        let apiMethod = () => eventApi.getEventReport(requestOptions);
        if (dealerId && orgId === undefined && siteId === undefined) {
            apiMethod = () => eventApi.getEventReportForDealer(dealerId, requestOptions);
        } else if (orgId && siteId === undefined) {
            apiMethod = () => eventApi.getEventReportForOrganization(orgId, requestOptions);
        } else if (orgId && siteId) {
            apiMethod = () => eventApi.getEventReportForSite(orgId, siteId, requestOptions);
        }
        return apiMethod;
    }, [dealerId, orgId, siteId, requestOptions, eventApi]);

    const resetFilters = useCallback(() => {
        setEmployeeToSearch(undefined);
        setCustomerToSearch(undefined);
        setFrom(moment().subtract(30, 'days'));
        setTo(moment().add(1, 'day'));
        selectEventType(null);
        selectEventName(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader
                helpVideoNum={HelpVideo.Report}
                isLoading={isLoading}
            >
                Reports
            </PageHeader>

            <OrgSelect
                selectedDealerId={dealerId}
                selectedOrgId={orgId}
                selectedSiteId={siteId || 0}
                dealerSelectIsEnabled
                orgSelectIsEnabled
                siteSelectIsEnabled
                includeAllSites
                bypassSiteContext={deassocciateSiteFromContext}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
            />

            <SearchBar
                defaultSearchText={searchText}
                search={search}
                onReset={resetFilters}
            />

            <div>
                <UserSelectContainer>
                    Employee :{' '}
                    <AsyncSelect
                        className={classes.userSelectControl}
                        cacheOptions
                        value={
                            employeeToSearch
                                ? { value: employeeToSearch, label: employeeToSearch }
                                : undefined
                        }
                        controlShouldRenderValue={Boolean(employeeToSearch)}
                        onChange={(value) => {
                            const val = value as
                                | { label: string; value: string }
                                | null
                                | undefined;
                            setEmployeeToSearch(val ? val.value : undefined);
                        }}
                        loadOptions={(inputValue) =>
                            new Promise((resolve) => {
                                employeeApi
                                    .getNameMatches(
                                        inputValue,
                                        orgId || undefined,
                                        siteId || undefined
                                    )
                                    .then((r) =>
                                        resolve(
                                            r.data && r.data.map((s) => ({ label: s, value: s }))
                                        )
                                    );
                            })
                        }
                        noOptionsMessage={(obj: { inputValue: string }) =>
                            obj.inputValue ? 'No matches found' : 'Start typing to search users'
                        }
                        isClearable
                    />
                </UserSelectContainer>
                <UserSelectContainer>
                    Customer :{' '}
                    <AsyncSelect
                        className={classes.userSelectControl}
                        cacheOptions
                        value={
                            customerToSearch
                                ? { value: customerToSearch, label: customerToSearch }
                                : undefined
                        }
                        controlShouldRenderValue={Boolean(customerToSearch)}
                        onChange={(value) => {
                            const val = value as
                                | { label: string; value: string }
                                | null
                                | undefined;
                            setCustomerToSearch(val ? val.value : undefined);
                        }}
                        loadOptions={(inputValue) =>
                            new Promise((resolve) => {
                                customerApi
                                    .getNameMatches(
                                        inputValue,
                                        orgId || undefined,
                                        siteId || undefined
                                    )
                                    .then((r) =>
                                        resolve(
                                            r.data && r.data.map((s) => ({ label: s, value: s }))
                                        )
                                    );
                            })
                        }
                        noOptionsMessage={(obj: { inputValue: string }) =>
                            obj.inputValue ? 'No matches found' : 'Start typing to search users'
                        }
                        isClearable
                    />
                </UserSelectContainer>
            </div>
            <DateAndTypeFilterContainer>
                <div>
                    From: <DatePicker value={from} onChange={(date) => setFrom(date)} />
                </div>
                <div>
                    To: <DatePicker value={to} onChange={(date) => setTo(date)} />
                </div>

                <TimeZoneSwitch
                    localTimeZoneSelected={filterByLocalTime}
                    onLocalTimeZoneSelected={setFilterByLocalTime}
                />

                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="event-type-select">Event Type</InputLabel>
                    <Select
                        inputProps={{ id: 'event-type-select' }}
                        value={eventType === null ? -1 : eventType}
                        onChange={(
                            e: ChangeEvent<{ name?: string | undefined; value: unknown }>
                        ) => {
                            const val = e.target.value as number;
                            selectEventType(val < 0 ? null : (val as number));
                        }}
                    >
                        <MenuItem value={-1}></MenuItem>
                        {eventTypeOptions.map((o) => (
                            <MenuItem key={o[0]} value={o[0]}>
                                {o[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="event-type-select">Event</InputLabel>
                    <Select
                        inputProps={{ id: 'event-type-select' }}
                        value={eventName === null ? -1 : eventName}
                        disabled={eventType === null}
                        onChange={(
                            e: ChangeEvent<{ name?: string | undefined; value: unknown }>
                        ) => {
                            const val = e.target.value as number;
                            selectEventName(val < 0 ? null : (val as number));
                        }}
                    >
                        <MenuItem value={-1}></MenuItem>
                        {eventNameOptions.map((o) => (
                            <MenuItem key={o[0]} value={o[0]}>
                                {o[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DateAndTypeFilterContainer>

            <ButtonsContainer>
                <ExportReportButton
                    reportRequest={reportRequest}
                    reportTarget="Event"
                />
            </ButtonsContainer>

            <div className={classes.paginationContainer}>
                <Pagination {...{ setPage, page, pageCount: numberOfPages }} />
                <ItemsPerPageInput value={itemsPerPage} onChange={setItemsPerPage} />
            </div>

            <ReportLogTable
                events={events}
                filterByLocalTime={filterByLocalTime}
            />
        </>
    );
};

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 100,
        flexGrow: 2,
        marginLeft: '10px',
    },
    userSelectControl: {
        minWidth: 400,
        marginLeft: '10px',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const UserSelectContainer = styled('div')({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: '20px 0px',
});

const DateAndTypeFilterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-end',
});
