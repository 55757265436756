import { useMemo } from 'react';
import { UserLevel } from '../../../enums/userLevel';

export const useCanSwitchSitesOrOrgsSelector = (roles: string[]) =>
    useMemo(
        () => ({
            canSwitchDealers: roles.indexOf(UserLevel.GlobalAdmin) !== -1,
            canSwitchOrgs: roles.indexOf(UserLevel.DealerAdmin) !== -1,
            canSwitchSites: roles.indexOf(UserLevel.OrgAdmin) !== -1,
        }),
        [roles]
    );
