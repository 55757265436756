export enum SystemErrorName {
    sensorBatteryLow = 0,
    inconsistentBox = 1,
    sensorTamper = 2,
    sensorMissing = 3,
    fpTerminalUnavailable = 4,
    dmpPanelUnavailable = 5
}

export const SystemErrorNameDisplay = new Map([
    [SystemErrorName.sensorBatteryLow, 'Sensor Battery Low'],
    [SystemErrorName.inconsistentBox, 'Box in Inconsistent State'],
    [SystemErrorName.sensorTamper, 'Sensor Tampered'],
    [SystemErrorName.sensorMissing, 'Sensor Missing'],
    [SystemErrorName.fpTerminalUnavailable, 'Fingerprint Terminal Unavailable'],
    [SystemErrorName.dmpPanelUnavailable, 'Panel Unavailable']
]);
