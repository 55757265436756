import { Button } from "@material-ui/core";
import React from "react";

interface Props {
    user: {
        id: number;
        fingerprintEnrolled: boolean;
        palmEnrolled: boolean;
    }
    disabled?: boolean;
    onSetUserIdToEnroll: (id: number) => void;
}

export function EnrollBiometricsButton({
    user,
    disabled = false,
    onSetUserIdToEnroll,
}: Props) {
    const handleClick = () => {
        onSetUserIdToEnroll(user.id);
    }

    return (
        <Button
            variant="outlined"
            onClick={handleClick}
            color={
                user.fingerprintEnrolled || user.palmEnrolled
                    ? 'default'
                    : 'primary'
            }
            disabled={disabled}
        >
            {user.fingerprintEnrolled
                ? 'Re-Enroll Fingerprint'
                : user.palmEnrolled
                    ? 'Re-Enroll Palm'
                    : 'Enroll Biometrics'}
        </Button>
    )
}
