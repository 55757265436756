import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useContext } from "react";
import { ValueType } from "react-select/src/types";

import { AuthContext } from "../../../auth/authContext";
import { OrgSiteContext } from "../../orgSiteContext";

import { SiteSelect } from "./SiteSelect";
import { OrganizationSelect } from "./OrganizationSelect";
import { DealerSelect } from "./DealerSelect";

const useStyles = (descriptive: boolean) => makeStyles({
    container: {
        marginBottom: "20px",
        display: "flex",
        gap: "5px",
        maxWidth: "1000px",
        flexDirection: descriptive ? "column" : "row"
    },
    select: {
        minWidth: "200px",
    },
});

interface Props {
    selectedDealerId?: number | null;
    selectedOrgId?: number | null;
    selectedSiteId?: number | null;
    orgSelectIsHidden?: boolean;
    orgSelectIsEnabled?: boolean;
    siteSelectIsEnabled?: boolean;
    dealerSelectIsEnabled?: boolean;
    includeAllSites?: boolean;
    bypassSiteContext?: boolean;
    onChange?: (val: {
        dealerId?: number | null;
        orgId?: number | null;
        siteId?: number | null;
    }) => void;
    descriptive?: boolean;
}

export const OrgSelect = ({
    selectedDealerId,
    selectedOrgId,
    selectedSiteId,
    dealerSelectIsEnabled = false,
    orgSelectIsEnabled = false,
    orgSelectIsHidden,
    siteSelectIsEnabled,
    bypassSiteContext,
    onChange,
    includeAllSites = false,
    descriptive = false,
}: Props) => {
    const classes = useStyles(descriptive)();

    const { isGlobalAdmin, orgId: assignedOrgId } = useContext(AuthContext);
    const { dealerId, setOrgId, setSiteId, setDealerId } = useContext(OrgSiteContext);

    const handleDealerSelect = useCallback((value: ValueType<{ value: number; label: string }>) => {
        if (!onChange) return;
        const val = value as { value: number; label: string };
        onChange({
            dealerId: val ? val.value : null,
            orgId: null,
            siteId: null,
        });
        setDealerId(val ? val.value : null);
    }, [setDealerId, onChange,]);

    const handleOrganizationSelect = useCallback((value: ValueType<{ value: number; label: string }>) => {
        if (!onChange) return;
        const val = value as { value: number; label: string };
        onChange({
            dealerId: dealerId,
            orgId: val ? val.value : null,
            siteId: null,
        });
        setOrgId(val ? val.value : null);
    }, [setOrgId, onChange, dealerId]);

    const handleSiteSelect = useCallback((value: ValueType<{ value: number; label: string }>) => {
        if (!onChange) return;
        const val = value as { value: number; label: string };
        onChange({
            dealerId: dealerId,
            orgId: selectedOrgId,
            siteId: val ? val.value : null,
        });
        setSiteId(val ? val.value : null);
    }, [setSiteId, onChange, selectedOrgId, dealerId]);

    return (
        <div className={classes.container}>
            <DealerSelect
                selectedDealerId={dealerId}
                handleChange={handleDealerSelect}
                showLabel={descriptive}
                fullWidth={descriptive}
                canGetOptions={dealerSelectIsEnabled}
                disabled={!isGlobalAdmin}
                className={classes.select}
            />

            {!orgSelectIsHidden && (
                <OrganizationSelect
                    selectedOrganizationId={selectedOrgId || null}
                    handleChange={handleOrganizationSelect}
                    dealerId={selectedDealerId || null}
                    showLabel={descriptive}
                    fullWidth={descriptive}
                    canGetOptions={orgSelectIsEnabled}
                    disabled={!!assignedOrgId}
                    className={classes.select}
                />
            )}

            {siteSelectIsEnabled && (
                <SiteSelect
                    selectedSiteId={selectedSiteId || null}
                    handleChange={handleSiteSelect}
                    organizationId={selectedOrgId || null}
                    showOptionAll={includeAllSites}
                    showLabel={descriptive}
                    fullWidth={true}
                    disabled={!!assignedOrgId && !bypassSiteContext}
                    className={classes.select}
                />
            )}
        </div>
    );
};
