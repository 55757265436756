import React from "react";
import { ValueType } from "react-select/src/types";

import { DialogSelect } from "../../dialogSelect";
import { FormField } from "../../formField";

import { useOrganizationOptions } from "./useOrganizationOptions";
import { useUserOrganizationOption } from "./useUserOrganizationOption";

interface Props {
    selectedOrganizationId: number | null;
    handleChange: (value: ValueType<{ value: number; label: string }>) => void;
    dealerId: number | null;
    showLabel: boolean;
    fullWidth: boolean;
    canGetOptions: boolean;
    disabled: boolean;
    className?: string | undefined;
}

export function OrganizationSelect({
    selectedOrganizationId,
    handleChange,
    dealerId,
    showLabel,
    fullWidth,
    canGetOptions,
    disabled,
    className,
}: Props) {
    const {
        selectedOrganizationOption,
        organizationOptions,
        isLoading,
    } = useOrganizationOptions(selectedOrganizationId, dealerId, canGetOptions);
    const userOrganizationOption = useUserOrganizationOption();

    return (
        <FormField label={showLabel ? "Organization" : undefined}>
            <DialogSelect
                options={organizationOptions}
                value={userOrganizationOption || selectedOrganizationOption}
                isDisabled={disabled}
                isClearable
                onChange={handleChange}
                placeholder="Select organization..."
                fullWidth={fullWidth}
                className={className}
                isLoading={isLoading}
            />
        </FormField>
    );
}
