import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useMemo, useContext, useCallback } from "react";
import useRouter from "use-react-router";

import { AuthContext } from "../../../auth/authContext";
import { Organization } from "../../../dtos/organization";
import { SiteAggregate } from "../../../dtos/siteAggregate";
import { AjaxResult } from "../../../enums/ajaxResult";
import { HelpVideo } from "../../../enums/helpVideo";
import { UserLevel } from "../../../enums/userLevel";
import { useOrgSiteContext } from "../../../hooks/useOrgSiteContext";
import { PageHeader } from "../../PageHeader";
import { OrgSelect } from "../../shared/OrgSelect";
import { useApi } from "../../useApi";

import { AddSiteDialog } from "./AddSiteDialog";
import { SitesTable } from "./table";

interface RouteState {
    serviceGuidFromParams: string | undefined;
    openAddSiteDialog: boolean;
}

export const SitesPage = () => {
    const classes = useStyles();
    const { match, history } = useRouter();
    const params = (match.params as { guid: string });
    const routeState = history.location.state as RouteState;
    const [serviceGuidFromParams, setServiceGuidFromParams] = useState<string | undefined>(
        (routeState && routeState.serviceGuidFromParams) || undefined
    );
    const [openAddSiteDialog, setOpenAddSiteDialog] = useState(
        (routeState && routeState.openAddSiteDialog) || false
    );
    const {
        dealerId,
        orgId,
        siteId: contextSiteId,
        siteChangeCallback,
    } = useOrgSiteContext<RouteState>(
        params.guid ? `sites/commission/${params.guid}` : `sites`,
        {
            serviceGuidFromParams: serviceGuidFromParams,
            openAddSiteDialog: openAddSiteDialog,
        },
    );
    const { enqueueSnackbar } = useSnackbar();
    const { siteApi, orgApi } = useApi();
    const { roles } = useContext(AuthContext);

    const userIsGlobalAdmin = useMemo(() => roles.indexOf(UserLevel.GlobalAdmin) !== -1, [roles]);
    const userIsDealer = useMemo(() => roles.indexOf(UserLevel.DealerAdmin) !== -1, [roles]);
    const viewIsReadOnly = !userIsGlobalAdmin && !userIsDealer;
    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const [org, setOrg] = useState<Organization | null>(null);
    useEffect(() => {
        if (orgId) {
            orgApi.getById(orgId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setOrg(r.data);
                }
            });
        } else {
            setOrg(null);
        }
    }, [orgId, orgApi]);

    const [sites, setSites] = useState<SiteAggregate[]>([]);
    const [sitesAreLoading, setSitesAreLoading] = useState(false);

    const loadSites = useCallback(() => {
        if (contextSiteId && orgId) {
            setSitesAreLoading(true);
            siteApi.getSitesAggregateForSite(orgId, contextSiteId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    enqueueSnackbar("ERROR: Failed to get sites from the server");
                }
                setSitesAreLoading(false);
            });
        } else if (orgId) {
            setSitesAreLoading(true);
            siteApi.getSitesAggregateForOrg(orgId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    enqueueSnackbar("ERROR: Failed to get sites from the server");
                }
                setSitesAreLoading(false);
            });
        } else if (dealerId) {
            setSitesAreLoading(true);
            siteApi.getForDealerAggregate(dealerId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    enqueueSnackbar("ERROR: Failed to get sites from the server");
                }
                setSitesAreLoading(false);
            });
        } else {
            setSites([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, contextSiteId, siteApi, dealerId]);

    useEffect(() => {
        loadSites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, contextSiteId, siteApi, dealerId]);

    const handleCloseAddSiteDialog = useCallback(() => {
        setOpenAddSiteDialog(false)
        history.push(history.location.pathname.replace(/\/commission\/.*/, ""));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSiteDelete = useCallback((id: number) => {
        setSites((prev) => prev.filter((s) => s.id !== id));
    }, []);

    useEffect(() => {
        if (params.guid) {
            setServiceGuidFromParams(params.guid);
            setOpenAddSiteDialog(true);
        }
    }, [params.guid]);

    return (
        <>
            <PageHeader
                helpVideoNum={HelpVideo.Site}
                isLoading={sitesAreLoading}
            >
                {`${org ? org.name : ""} `}Sites
            </PageHeader>
            <OrgSelect
                selectedOrgId={orgId || null}
                selectedDealerId={dealerId || null}
                selectedSiteId={contextSiteId || null}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
                orgSelectIsEnabled
                dealerSelectIsEnabled
                siteSelectIsEnabled
                bypassSiteContext={deassocciateSiteFromContext}
            />
            {viewIsReadOnly ? null : (
                <Button
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddSiteDialog(true)}
                    disabled={!orgId}
                    title={!orgId ? "Select an organization to add a site to" : ""}
                >
                    Add New
                </Button>
            )}

            <SitesTable
                sites={sites}
                sitesAreLoading={sitesAreLoading}
                contextDealerId={dealerId}
                contextOrgId={orgId}
                onDeleteSite={handleSiteDelete}
                onSiteActivationChange={loadSites}
            />

            <AddSiteDialog
                isOpen={openAddSiteDialog}
                onClose={handleCloseAddSiteDialog}
                onSiteAdded={loadSites}
                serviceGuidFromParams={serviceGuidFromParams}
                orgId={orgId}
            >
                {serviceGuidFromParams && (
                    <OrgSelect
                        selectedOrgId={orgId || null}
                        selectedDealerId={dealerId || null}
                        selectedSiteId={contextSiteId || null}
                        onChange={(val: {
                            dealerId?: number | null;
                            orgId?: number | null;
                            siteId?: number | null;
                        }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
                        orgSelectIsEnabled
                        dealerSelectIsEnabled
                        siteSelectIsEnabled={false}
                        bypassSiteContext={deassocciateSiteFromContext}
                        descriptive={true}
                    />
                )}
            </AddSiteDialog>
        </>
    );
};

const useStyles = makeStyles({
    addButton: {
        display: "flex",
        marginBottom: "20px",
        marginTop: "20px",
    },
});
